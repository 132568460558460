<template>
  <b-modal
    v-model="model"
    centered
    :hide-footer="true"
    :title="'Añadir descuento global'"
  >
    <div class="my-1">
      <validation-observer ref="globalDiscount">
        <validation-provider
          #default="{ errors }"
          name="descuento global"
          rules="required"
        >
          <b-form-input
            id="global-discount"
            v-model="discount"
            name="facility-price"
            placeholder="Descuento global"
          />
          <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </validation-observer>
    </div>
    <hr />
    <div class="d-flex justify-content-end">
      <b-button class="mr-2" @click="model = false">
        {{ $t("Cancelar") }}
      </b-button>
      <b-button @click="handlerAdd"> Añadir </b-button>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ordersApi from "@/api/orders-api";
import { ValidationProvider, ValidationObserver } from "vee-validate";

export default {
  name: "AddGlobalDiscountModal",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      discount: "",
    };
  },
  computed: {
    ...mapGetters({
      order: "orders/getOrder",
    }),
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    ...mapActions({
      getOrder: "orders/getOrder",
    }),
    async handlerAdd() {
      const validate = await this.$refs.globalDiscount.validate();
      if (!validate) return;

      try {
        await ordersApi.savePriceDiscount(this.order.id, this.discount);
      } finally {
        this.getOrder(this.$route.params.id);
        this.model = false;
      }
    },
  },
};
</script>
