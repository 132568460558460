<template>
  <b-modal v-model="model" centered :hide-footer="true"
    :title="'Añadir precio de instalacion'">
    <div class="my-1">
      <validation-observer ref="facilityPrice">
        <validation-provider
          #default="{ errors }"
          name="precio de instalacion"
          rules="required"
        >
          <b-form-input
            id="facility-price"
            v-model.number="facilityPrice"
            name="facility-price"
            placeholder="Precio de instalacion"
          />
          <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </validation-observer>
    </div>
    <div class="d-flex justify-content-end">
      <b-button class="mr-2" @click="model = false">
        {{ $t("Cancelar") }}
      </b-button>
      <b-button @click="handlerAdd"> Añadir </b-button>
    </div>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { mapGetters, mapActions } from "vuex";
import ordersApi from "@/api/orders-api";

export default {
  name: "AddFacilityPriceModal",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data () {
    return {
      facilityPrice: ''
    }
  },
  computed: {
    ...mapGetters({
      order: "orders/getOrder",
    }),
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    ...mapActions({
      getOrder: "orders/getOrder",
    }),
    async handlerAdd() {
      const validate = await this.$refs.facilityPrice.validate();
      if (!validate) return;

      try {
        await ordersApi.savePriceInstallation(this.order.id, this.facilityPrice);
      } finally {
        this.getOrder(this.$route.params.id);
        this.model = false;
      }
    },
  },
};
</script>
