<template>
  <b-row>
    <b-col cols="12">
      <div class="d-flex justify-content-between align-items-center pt-50 mb-2">
        <div>
          <h2 class="font-weight-bolder m-0 hGoBack">
            <span @click="$router.go(-1)">
              <b-button variant="white" class="btn-icon rounded-circle mr-50 text-danger bg-white" style="padding: 0.5px">
                <feather-icon icon="ArrowLeftCircleIcon" size="35" />
              </b-button>
            </span>
            Estado del pedido
          </h2>
        </div>
      </div>
      <b-row v-if="orderPrepare">
        <b-col cols="12">
          <b-card class="height-card px-2">
            <b-row class="position-relative justify-content-center">
              <b-col cols="12" class="m-1 seguimiento p-2">
                <div>
                  <span class="size-24 pb-3">Seguimiento</span>
                </div>
                <b-row class="mt-2">
                  <b-col v-if="!ready">
                    <b-row>
                      <b-col>
                        <b-row
                          class="align-items-center justify-content-center"
                        >
                          <b-col cols="3" class="text-center">
                            <feather-icon
                              icon="EditIcon"
                              size="18"
                              class="mr-50 icon-status"
                            />
                          </b-col>
                          <b-col>
                            <div>
                              <strong><span>Pendiente</span></strong>
                            </div>
                            <span v-if="pendingCheck">{{ pending }}</span>
                          </b-col>
                        </b-row>
                      </b-col>
                      <b-col v-if="reviewCheck">
                        <b-row class="align-items-center justify-content-center">
                          <b-col cols=3 class="text-center">
                            <iconReview />
                          </b-col>
                          <b-col>
                            <div><strong><span>{{ $t('status.orders.revision') }}</span></strong></div>
                          </b-col>
                        </b-row>
                      </b-col>
                      <b-col :class="{ grey_status: !budgetCheck }">
                        <b-row
                          class="align-items-center justify-content-center"
                        >
                          <b-col cols="3" class="text-center">
                            <feather-icon
                              icon="FileTextIcon"
                              size="18"
                              class="mr-50 icon-status"
                            />
                          </b-col>
                          <b-col>
                            <div>
                              <strong><span>Propuesta</span></strong>
                            </div>
                            <span v-if="budget && budgetCheck">{{
                              budget
                            }}</span>
                          </b-col>
                        </b-row>
                      </b-col>
                      <b-col :class="{ grey_status: !budget_adminCheck }">
                        <b-row
                          class="align-items-center justify-content-center"
                        >
                          <b-col cols="3" class="text-center">
                            <feather-icon
                              icon="FileTextIcon"
                              size="18"
                              class="mr-50 icon-status"
                            />
                          </b-col>
                          <b-col>
                            <div>
                              <strong><span>Propuesta aceptada</span></strong>
                            </div>
                            <span v-if="budget_admin && budget_adminCheck">{{
                              budget_admin
                            }}</span>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col v-else>
                    <b-row>
                      <b-col
                        md="6"
                        lg="6"
                        xl="3"
                        class="mb-1"
                        :class="{ grey_status: !readyCheck }"
                      >
                        <b-row
                          class="align-items-center justify-content-center"
                        >
                          <b-col cols="3" class="text-center">
                            <iconPreparacion class="icon-status" />
                          </b-col>
                          <b-col>
                            <div>
                              <strong><span>En preparación</span></strong>
                            </div>
                            <span v-if="ready && readyCheck">{{ ready }}</span>
                          </b-col>
                        </b-row>
                      </b-col>
                      <b-col
                        md="6"
                        lg="6"
                        xl="3"
                        class="mb-1"
                        :class="{ grey_status: !sendCheck }"
                      >
                        <b-row
                          class="align-items-center justify-content-center"
                        >
                          <b-col cols="3" class="text-center">
                            <iconEnviado class="icon-status" />
                          </b-col>
                          <b-col>
                            <div>
                              <strong><span>Enviado</span></strong>
                            </div>
                            <span v-if="send && sendCheck">{{ send }}</span>
                          </b-col>
                        </b-row>
                      </b-col>
                      <b-col
                        md="6"
                        lg="6"
                        xl="3"
                        class="mb-1"
                        :class="{ grey_status: !castCheck }"
                      >
                        <b-row
                          class="align-items-center justify-content-center"
                        >
                          <b-col cols="3" class="text-center">
                            <iconReparto class="icon-status" />
                          </b-col>
                          <b-col>
                            <div>
                              <strong><span>En reparto</span></strong>
                            </div>
                            <span v-if="cast && castCheck">{{ cast }}</span>
                          </b-col>
                        </b-row>
                      </b-col>
                      <b-col
                        md="6"
                        lg="6"
                        xl="3"
                        class="mb-1"
                        :class="{ grey_status: !deliveredCheck }"
                      >
                        <b-row
                          class="align-items-center justify-content-center"
                        >
                          <b-col cols="3" class="text-center">
                            <iconEntregado class="icon-status-entregado" />
                          </b-col>
                          <b-col>
                            <div>
                              <strong><span>Entregado</span></strong>
                            </div>
                            <span v-if="delivered">{{ delivered }}</span>
                          </b-col>
                        </b-row>
                      </b-col>
                    </b-row>
                  </b-col>
                  <b-col cols="3" class="text-right">
                    <b-button
                      v-if="
                        order.status_last['type'] == 'budget' &&
                        currentUser.id === order.user.id
                      "
                      class="mr-1"
                      @click="changeStatusFunction('budget_admin')"
                    >
                      ACEPTAR PRESUPUESTO
                    </b-button>
                    <b-button
                      v-else-if="
                        order.status_last['type'] == 'budget_admin' &&
                        currentUser.roles[0].name === 'super_admin'
                      "
                      class="mr-1"
                      @click="changeStatusFunction('ready')"
                    >
                      ACEPTAR PEDIDO
                    </b-button>
                    <UploadStatus
                      v-else-if="statusProx"
                      :id-order="order.id"
                      :status="statusProx"
                    />
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="12" class="m-1 p-2">
                <!-- datos -->
                <b-row>
                  <b-col>
                    <div>
                      <strong><span>Nº Pedido:</span></strong>
                    </div>
                    <span
                      >{{ dateColumnYear(order.created_at) }}/T{{
                        order.id
                      }}</span
                    >
                  </b-col>
                  <b-col>
                    <div>
                      <strong><span>Fecha del pedido:</span></strong>
                    </div>
                    <span>{{ dateColumn(order.created_at) }}</span>
                  </b-col>
                  <b-col v-if="currentUser.roles[0].name !== 'user'">
                    <div>
                      <strong><span>Cliente:</span></strong>
                    </div>
                    <span>{{ order.client.name }}</span>
                  </b-col>
                  <b-col>
                    <div>
                      <strong><span>Responsable:</span></strong>
                    </div>
                    <span>{{ order.user.email }}</span>
                  </b-col>
                </b-row>
                <hr />
                <!-- ficheros -->
                <b-row class="mt-2">
                  <b-col v-if="order.budget">
                    <b-link
                      :href="order.budget.path"
                      target="_blank"
                      class="d-inline-block px-50"
                    >
                      <span> Ver presupuesto </span>
                    </b-link>
                  </b-col>
                  <b-col v-if="order.delivery_note">
                    <b-link
                      :href="order.delivery_note.path"
                      target="_blank"
                      class="d-inline-block px-50"
                    >
                      Ver albarán
                    </b-link>
                  </b-col>
                  <b-col v-if="order.invoice">
                    <b-link
                      :href="order.invoice.path"
                      target="_blank"
                      class="d-inline-block px-50"
                    >
                      Ver factura
                    </b-link>
                  </b-col>
                  <b-col class="text-right">
                    <UploadPDF :id-order="order.id" />
                  </b-col>
                </b-row>
              </b-col>

              <b-col cols="12" class="p-0">
                <b-row>
                  <Tables
                    :lines-cortinas="linesCortinas"
                    :lines-cuadros="linesCuadros"
                    :lines-extras="linesExtras"
                    :order="order"
                  />
                </b-row>
              </b-col>
              <template v-if="reviewCheck">
                <b-col cols="12">
                  <p>{{ $t('review_options') }}</p>
                </b-col>
                <b-col class="d-flex align-items-end">
                  <b-button @click="modalTransport = true" class="mr-1">
                    Transporte
                  </b-button>
                  <b-button @click="modalFacility = true" class="mr-1">
                    Instalacion
                  </b-button>
                  <b-button @click="modalGlobalDiscount = true" class="mr-1">
                    Descuento global
                  </b-button>
                  <b-button @click="modalPackaging = true">
                    Embalaje
                  </b-button>
                </b-col>
                <b-col class="d-flex align-items-end justify-content-end">
                  <b-button @click="finish">
                    Finalizar revisión
                  </b-button>
                </b-col>
              </template>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-col>
    <AddFacilityPriceModal v-model="modalFacility" />
    <AddGlobalDiscountModal v-model="modalGlobalDiscount" />
    <AddTransportPriceModal v-model="modalTransport" />
    <AddPackagingModal v-model="modalPackaging" :orderFirst="order"/>
  </b-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { BRow, BCol, BCard, BLink, BButton } from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import {
  TimestampToFlatPickr,
  TimestampToFlatPickrOnlyYear,
} from "@/libs/helpers";
import { config } from "@/shared/app.config";
import iconPreparacion from "@/assets/images/status/preparacion";
import iconEnviado from "@/assets/images/status/enviado";
import iconReparto from "@/assets/images/status/reparto";
import iconEntregado from "@/assets/images/status/entregado";
import iconReview from '@/assets/images/status/review'
import UploadStatus from "./UploadStatus";
import UploadPDF from "./Upload";
import Tables from "./Tables";
import AddGlobalDiscountModal from "@/components/orders/modals/AddGlobalDiscountModal.vue";
import AddFacilityPriceModal from "@/components/orders/modals/AddFacilityPriceModal.vue";
import AddTransportPriceModal from "@/components/orders/modals/AddTransportPriceModal.vue"
import AddPackagingModal from "@/components/orders/modals/AddPackagingModal.vue"

export default {
  components: {
    BButton,
    BLink,
    BRow,
    BCol,
    BCard,
    Tables,
    iconPreparacion,
    iconEnviado,
    iconReparto,
    iconEntregado,
    UploadPDF,
    UploadStatus,
    iconReview,
    AddGlobalDiscountModal,
    AddFacilityPriceModal,
    AddTransportPriceModal,
    AddPackagingModal
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      statusOrderVariant: config.statusOrderVariants,
      dir: false,
      orderPrepare: false,
      ready: false,
      send: false,
      cast: false,
      delivered: false,
      budget: false,
      budget_admin: false,
      pending: false,
      readyCheck: false,
      sendCheck: false,
      castCheck: false,
      reviewCheck: false,
      deliveredCheck: false,
      budgetCheck: false,
      budget_adminCheck: false,
      pendingCheck: false,
      statusProx: "",
      url: "",
      statusLast: "",
      modalFacility: false,
      modalGlobalDiscount: false,
      modalPackaging: false,
      modalTransport: false,
    };
  },
  computed: {
    ...mapGetters({
      order: "orders/getOrder",
      currentUser: "auth/getUser",
    }),
    linesCortinas() {
      const lines = [];
      if (this.order) {
        this.order.lines.forEach((e) => {
          const attr = JSON.parse(e.atributos);
          if (attr.tipo && attr.tipo === "cortinas") {
            e.attr = attr;
            lines.push(e);
          }
        });
      }
      return lines;
    },
    linesCuadros() {
      const lines = [];
      if (this.order) {
        this.order.lines.forEach((e) => {
          const attr = JSON.parse(e.atributos);
          if (attr.tipo && attr.tipo === "cuadro control") {
            e.attr = attr;
            lines.push(e);
          }
        });
      }
      return lines;
    },
    linesExtras() {
      const lines = [];
      if (this.order) {
        this.order.lines.forEach((e) => {
          const attr = JSON.parse(e.atributos);
          if (attr.tipo && attr.tipo === "extras") {
            e.attr = attr;
            lines.push(e);
          }
        });
      }
      return lines;
    },
  },
  methods: {
    ...mapActions({
      getOrder: "orders/getOrder",
      changeStatus: "orders/changeStatusOrder",
      finishRevision: "orders/finishRevision"
    }),
    dateColumn(time) {
      return TimestampToFlatPickr(time);
    },
    dateColumnYear(time) {
      return TimestampToFlatPickrOnlyYear(time);
    },
    chargeData() {
      if (this.order) {
        this.order.status.forEach((e) => {
          switch (e.type) {
            case "pending":
              this.pending = this.dateColumn(e.created);
              break;
            case "budget":
              this.budget = this.dateColumn(e.created);
              break;
            case "budget_admin":
              this.budget_admin = this.dateColumn(e.created);
              break;
            case "delivered":
              this.delivered = this.dateColumn(e.created);
              console.log(e);
              break;
            case "cast":
              this.cast = this.dateColumn(e.created);
              console.log(e);
              break;
            case "send":
              this.send = this.dateColumn(e.created);
              console.log(e);
              break;
            case "ready":
              this.ready = this.dateColumn(e.created);
              console.log(e);
              break;
          }
        });
        switch (this.order.status_last["type"]) {
          case "cast":
            this.statusProx = "delivered";
            break;
          case "send":
            this.statusProx = "cast";
            break;
          case "ready":
            this.statusProx = "send";
            break;
        }
        if (this.order.status_last) {
          this.statusLast = this.order.status_last.type;
          switch (this.statusLast) {
            case "pending":
              this.pendingCheck = true;
              break;
            case 'revision':
              this.pendingCheck = true
              this.reviewCheck = true
              break
            case "budget":
              this.pendingCheck = true;
              this.budgetCheck = true;
              break;
            case "budget_admin":
              this.pendingCheck = true;
              this.budgetCheck = true;
              this.budget_adminCheck = true;
              break;
            case "ready":
              this.pendingCheck = true;
              this.budgetCheck = true;
              this.budget_adminCheck = true;
              this.readyCheck = true;
              break;
            case "send":
              this.pendingCheck = true;
              this.budgetCheck = true;
              this.budget_adminCheck = true;
              this.readyCheck = true;
              this.sendCheck = true;
              break;
            case "cast":
              this.pendingCheck = true;
              this.budgetCheck = true;
              this.budget_adminCheck = true;
              this.readyCheck = true;
              this.sendCheck = true;
              this.castCheck = true;
              break;
            case "delivered":
              this.pendingCheck = true;
              this.budgetCheck = true;
              this.budget_adminCheck = true;
              this.readyCheck = true;
              this.sendCheck = true;
              this.castCheck = true;
              this.deliveredCheck = true;
              break;
          }
        }

        this.orderPrepare = true;
      }
    },
    changeStatusFunction(status) {
      this.changeStatus({
        id: this.$route.params.id,
        status,
        planned_delivery_date: "",
        url_seguimiento: "",
      });
    },
    finish() {
      this.finishRevision({ order: this.order.id })
    }
  },
  async created() {
    await this.getOrder(this.$route.params.id);
    await this.chargeData();
  },
};
</script>
