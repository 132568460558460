<template>
  <b-modal
    v-model="model"
    centered
    :hide-footer="true"
    :title="'Añadir precio de transporte'"
  >
    <div class="my-1">
      <validation-observer ref="transportPrice">
        <validation-provider
          #default="{ errors }"
          name="precio de transporte"
          rules="required"
        >
          <b-form-input
            id="transport-price"
            v-model="transportPrice"
            name="transport-price"
            placeholder="precio de transporte"
          />
          <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </validation-observer>
    </div>
    <hr />
    <div class="d-flex justify-content-end">
      <b-button class="mr-2" @click="model = false">
        {{ $t("Cancelar") }}
      </b-button>
      <b-button @click="handlerAdd">Añadir</b-button>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ordersApi from "@/api/orders-api";

export default {
  name: "AddTransportPriceModal",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      transportPrice: "",
    };
  },
  computed: {
    ...mapGetters({
      order: "orders/getOrder",
    }),
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  methods: {
    ...mapActions({
      getOrder: "orders/getOrder",
    }),
    async handlerAdd() {
      const validate = await this.$refs.transportPrice.validate();
      if (!validate) return;

      try {
        await ordersApi.savePriceTransport(this.order.id, this.transportPrice);
      } finally {
        this.getOrder(this.$route.params.id);
        this.model = false;
      }
    },
  },
};
</script>
