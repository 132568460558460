<template>
  <div>
    <b-button
      id="show-btn click"
      class="mr-1 text-nowrap text-uppercase"
      variant="primary"
      @click="$bvModal.show('uploadStatus')"
    >
      {{ 'Actualizar pedido' }}
    </b-button>
    <b-modal
      id="uploadStatus"
      :hide-footer="true"
      size="lg"
    >
      <validation-observer ref="uploadStatus">
        <b-card-title
          title-tag="h1"
          class="font-weight-bolder mb-1 text-center"
        >
        Actualizar pedido
        </b-card-title>
        <div class="asignar-trabajos-modal">
          <b-form
            class="mt-2"
            @submit.prevent="handleSubmituploadStatus"
            @keydown.enter="handleSubmituploadStatus"
          >
            <b-container class="pl-3 pr-3">
              <b-row
                class="justify-content-center middle"
                align-v="center"
              >
                <b-col lg="8">
                  <b-form-group
                    :label="'Estado'"
                    label-for="estado"
                  >
                    <v-select
                      v-model="estado"
                      label="name"
                      :options="estados"
                      :reduce="option => option.value"
                    />
                  </b-form-group>
                </b-col>
                <b-col lg="8">
                  <b-form-group
                    :label="'Fecha'"
                    label-for="date"
                  >
                    <flat-pickr
                      v-model="date"
                      name="date"
                      class="form-control"
                    />
                  </b-form-group>
                </b-col>
                <b-col lg="8">
                  <b-form-group
                    :label="'Url de seguimiento del envío'"
                    label-for="url"
                  >
                    <b-form-input
                      v-model="url"
                      class="form-control-merge"
                      name="url"
                      placeholder="URL"
                    />
                  </b-form-group>

                </b-col>
              </b-row>
              <b-row class="text-center mb-3">
                <b-col>
                  <b-button
                    type="submit"
                    variant="primary"
                    class="mt-1 mr-1 text-uppercase"
                  >
                    {{ 'Actualizar pedido' }}
                  </b-button>
                </b-col>
              </b-row>
            </b-container>
          </b-form>
        </div>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import flatPickr from "vue-flatpickr-component";
import { FlatPickrToTimestamp, TimestampToFlatPickr } from "@/libs/helpers";
import moment from "moment";
import {
  BButton,
  BCardTitle,
  BCol,
  BContainer,
  BForm,
  BFormGroup,
  BLink,
  BRow,
  BFormInput,
} from 'bootstrap-vue'

export default {
  name: 'uploadStatus',
  components: {
    BButton,
    BForm,
    BFormGroup,
    BRow,
    BCol,
    ValidationObserver,
    BLink,
    BCardTitle,
    BContainer,
    BFormInput,
    vSelect,
    flatPickr,
    FlatPickrToTimestamp,
  },
  props: {
    idOrder: {
      type: Number,
      required: true,
    },
    status: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      albaran: '',
      factura: '',
      tipouploadStatus: '',
      url: '',
      estado: this.status,
      date: new Date(),
      estados: [
        {
          name: 'En preparación',
          value: 'ready',
        },
        {
          name: 'Enviado',
          value: 'send',
        },
        {
          name: 'En reparto',
          value: 'cast',
        },
        {
          name: 'Entregado',
          value: 'delivered',
        },
      ],
    }
  },
  methods: {
    ...mapActions({
      changeStatus: 'orders/changeStatusOrder',
    }),
    handleSubmituploadStatus() {
      let dateFormat = null
      if (this.date) {
        dateFormat = FlatPickrToTimestamp(this.date)
      }
      this.changeStatus({
        id: this.$route.params.id,
        status: this.estado,
        planned_delivery_date: '',
        created_at: dateFormat,
        url_seguimiento: this.url,
      })
    },
  },
  async created() {
    const date = new Date()

    const day = date.getDate()
    const month = date.getMonth() + 1
    const year = date.getFullYear()

    if (month < 10) {
      this.date = `${day}/0${month}/${year}`
    } else {
      this.date = `${day}/${month}/${year}`
    }
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
