<template>
  <b-modal
    v-model="model"
    centered
    :hide-footer="true"
    :title="'Cambiar embalaje'"
  >
    <div class="my-1">
      <validation-observer ref="packagingPrice">
        <validation-provider
            #default="{ errors }"
            name="packagingTYpe"
            rules="required"
            class="mt-1"
        >
          <b-form-group
              label="Tipo"
              label-for="packaging"
          >
            <v-select
                v-model="packagingType"
                label="name"
                :options="selectTypePackaging"
                :filterable="false"
                :searchable="false"
            />
          </b-form-group>
          <small class="text-danger mb-1">{{ errors[0] }}</small>
        </validation-provider>
        <validation-provider
          #default="{ errors }"
          name="precio de embalaje"
          rules="required"
        >
          <b-form-group
              label="Precio"
              label-for="packaging-price"
          >
          <b-form-input
            id="packaging-price"
            v-model="packagingPrice"
            name="packaging-price"
            placeholder="precio de embalaje"
          />
          </b-form-group>
          <small ref="errorField" class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </validation-observer>
    </div>
    <hr />
    <div class="d-flex justify-content-end">
      <b-button class="mr-2" @click="model = false">
        {{ $t("Cancelar") }}
      </b-button>
      <b-button @click="handlerAdd">Añadir</b-button>
    </div>
  </b-modal>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ordersApi from "@/api/orders-api";
import { BFormGroup } from "bootstrap-vue";
import vSelect from 'vue-select'

export default {
  name: "AddPackagingModal",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    orderFirst: {
      type: Object,
      required: true
    }
  },
  components: {
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    vSelect
  },
  data() {
    return {
      packagingType: '',
      packagingPrice: '',
      selectTypePackaging: [
        {
          name: `${this.$t('wood_packaging')}`,
          value: 'packaging_wood',
        },
        {
          name: `${this.$t('standard_packaging')}`,
          value: 'packaging_standard',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      order: "orders/getOrder",
    }),
    model: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  watch: {
    packagingType(v){
      v.value === 'packaging_standard' ? this.packagingPrice = this.orderFirst.packaging_standard : this.packagingPrice = this.orderFirst.packaging_wood
    },
    orderFirst: {
      handler(newOrderFirst, oldOrderFirst) {
        const correspondingOption = this.selectTypePackaging.find(option => option.value === newOrderFirst.packaging_type);
        if (correspondingOption) {
          this.packagingType = correspondingOption;
        }
        this.packagingPrice = newOrderFirst.packaging_type === 'packaging_standard' ? newOrderFirst.packaging_standard : newOrderFirst.packaging_wood;
      },
    }
  },
  methods: {
    ...mapActions({
      getOrder: "orders/getOrder",
    }),
    async handlerAdd() {
      const validate = await this.$refs.packagingPrice.validate();
      if (!validate) return;

      try {
        await ordersApi.savePackaging(this.order.id, this.packagingPrice, this.packagingType.value);
      } finally {
        this.getOrder(this.$route.params.id);
        this.model = false;
      }
    },
  },
};
</script>
